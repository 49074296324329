import React, { useState, useEffect } from 'react';
import axios from 'axios';
import PhoneNumberInput from './PhoneNumberInput';
import DropdownMenu from './DropdownMenu';
import Cookies from 'js-cookie';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import CryptoJS from 'crypto-js';

const encrypt = (data) => {
  const cipherText = CryptoJS.AES.encrypt(data, process.env.REACT_APP_CRYPTR_SECRET).toString();
  return cipherText;
};
const decrypt = (cipher) => {
  const bytes = CryptoJS.AES.decrypt(cipher, process.env.REACT_APP_CRYPTR_SECRET);
  const plainText = bytes.toString(CryptoJS.enc.Utf8);
  return plainText;
};

const ReminderPopup = ({ onClose, accesstoken }) => {
  const [loggedIn, setLoggedIn] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState(null);
  const [rep, setrep] = useState(null);
  const [dialCode, setdialCode] = useState(0);
  const [phoneCountry, setphoneCountry] = useState('');
  const [gatewaydetails, setGatewaydetails] = useState([{}]);
  const [selectedCarrier, setSelectedCarrier] = useState(null);
  const [carrierOptions, setCarrierOptions] = useState([]);
  const [otp, setOtp] = useState('');
  const [showOTP, setShowOTP] = useState(false);
  const [shows, setShows] = useState([]);

  useEffect(() => {
    // Get the unsubscribe button and popup after the component has mounted
    const unsubscribeButton = document.querySelector('.unsubscribe-button');
    const unsubscribePopup = document.querySelector('.unsubscribe-popup');
    const loggedInCookie = Cookies.get('AnimeWatchDog_LoggedIn');

    const setUserConfig = async (file) => {
      await axios(file)
        .then(function (response) {
          setShows([...response.data['documents']]);
        }).catch(function (error) {
          handleLogout();
        })
    }

    if (loggedInCookie === 'true') {
      // Automatically log in the user using stored information
      const userDataCookie = Cookies.get('AnimeWatchDog_UserData');
      if (userDataCookie) {
        const parsedUserData = JSON.parse(decrypt(userDataCookie));
        setUserConfig(parsedUserData['config']);
        setrep(parsedUserData['rep']);
        setLoggedIn(true);
      }
    }
  
    // Check if the elements exist before adding event listeners
    if (unsubscribeButton && unsubscribePopup) {
      // Add event listener to show/hide popup on hover
      unsubscribeButton.addEventListener('mouseenter', () => {
        unsubscribePopup.style.display = 'block';
      });
  
      unsubscribeButton.addEventListener('mouseleave', () => {
        unsubscribePopup.style.display = 'none';
      });
  
      // Cleanup event listener on component unmount
      return () => {
        unsubscribeButton.removeEventListener('mouseenter', () => {
          unsubscribePopup.style.display = 'block';
        });
  
        unsubscribeButton.removeEventListener('mouseleave', () => {
          unsubscribePopup.style.display = 'none';
        });
      };
    }
  }, []); // Empty dependency array ensures the effect runs only once


  const handleLogin = async () => {
    setrep(gatewaydetails.find(item => item.hasOwnProperty(selectedCarrier.label))[selectedCarrier.label].replace('number', phoneNumber.slice(dialCode)));
    var otp_data = JSON.stringify({
      "recipient": gatewaydetails.find(item => item.hasOwnProperty(selectedCarrier.label))[selectedCarrier.label].replace('number', phoneNumber.slice(dialCode))
    })
    var otp_config = {
      method: 'post',
      url: 'https://api.prakrut.dev/request_token',
      headers: {
        'Content-Type': 'application/json',
      },
      data: otp_data
    };
    await axios(otp_config).then(function (response) {setShowOTP(true);}).catch(function (error) {
      console.log(error)
    });
    
  };
// eslint-disable-next-line
  const handleLogout = () => {
    // Perform logout logic here
    setLoggedIn(false);
    // Clear stored login information and user data from cookies
    Cookies.remove('AnimeWatchDog_LoggedIn');
    Cookies.remove('AnimeWatchDog_UserData');
  };

  const handleOtpChange = (event) => {
    const { value } = event.target;
    const numericValue = value.replace(/\D/g, ""); // Remove non-numeric characters
    setOtp(numericValue);
  };

  const handleUnsubscribe = async (showId) => {
    // Implement logic to unsubscribe from the show with the provided ID
    var data = JSON.stringify({
      "_id": showId,
    })
    var config = {
      method: 'post',
      url: 'https://api.prakrut.dev/unsubscribe',
      headers: {
        'Content-Type': 'application/json',
      },
      data: data
    };
    await axios(config)
      .then(async function (response) {
        var data = JSON.stringify({
          "collection": "Anime",
          "database": "Anime",
          "dataSource": "RaspberryPi",
          "filter": {
            "recipient": rep,
          },
          "projection": {
            "show": 1,
            "_id": 1,
            "roll": 1
          },
          "sort": {"Last Published":-1}
        });
  
        var config = {
          method: 'post',
          url: 'https://us-east-1.aws.data.mongodb-api.com/app/data-akark/endpoint/data/v1/action/find',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${accesstoken}`,
          },
          data: data
        };
        await axios(config)
        .then(function (response) {
          setShows([...response.data['documents']]);
        })

      }).catch(function (error) {console.log(error)});
  };

  const UnsubAll = async () => {
    var unsub_all_data = JSON.stringify({
      "user": rep,
    })
    var unsub_all_config = {
      method: 'post',
      url: 'https://api.prakrut.dev/unsubscribe',
      headers: {
        'Content-Type': 'application/json',
      },
      data: unsub_all_data
    };
    await axios(unsub_all_config)
      .then(async function (response) {}).catch(function (error) {console.log(error)});
  }

  const handleKeyDown = (event) => {
    if (event.keyCode === 13) {
      handleVerifyOTP();
    }
  };
  
  const handleVerifyOTP = async () => {
    var otp_v_data = JSON.stringify({
      "recipient": rep,
      "OTP": otp
    })
    var otp_v_config = {
      method: 'post',
      url: 'https://api.prakrut.dev/verify_token',
      headers: {
        'Content-Type': 'application/json',
      },
      data: otp_v_data
    };
    await axios(otp_v_config)
      .then(async function (response) {
        var data = JSON.stringify({
          "collection": "Anime",
          "database": "Anime",
          "dataSource": "RaspberryPi",
          "filter": {
            "recipient": rep,
          },
          "projection": {
            "show": 1,
            "_id": 1,
            "roll": 1
          },
          "sort": {"Last Published":-1}
        });
  
        var config = {
          method: 'post',
          url: 'https://us-east-1.aws.data.mongodb-api.com/app/data-akark/endpoint/data/v1/action/find',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${accesstoken}`,
          },
          data: data
        };
        await axios(config)
        .then(function (response) {
          setShows([...response.data['documents']]);
        })
        const userData = {config: config, rep: rep, phoneNumber: phoneNumber, selectedCarrier: selectedCarrier};
        setLoggedIn(true);
        const acceptedCookie = Cookies.get('AnimeWatchDog_Accepted');
        if (acceptedCookie === 'true') {
          Cookies.set('AnimeWatchDog_LoggedIn', 'true', { expires: 14 });
          Cookies.set('AnimeWatchDog_UserData', encrypt(JSON.stringify(userData)), { expires: 14 });
        }
      }).catch(function (error) {
        setOtp('');
      });
  };

  const handleCarrierChange = (carrier) => {
    setSelectedCarrier(carrier);
  };

  const handlePhoneNumberChange = async (value, show, e, formattedValue) => {
    if (show['name'] !== phoneCountry) {
      setphoneCountry(show['name']);
      var carrier = JSON.stringify({
        "collection": "Carrier Info",
        "database": "Anime",
        "dataSource": "RaspberryPi",
        "filter": {
          "Country": show['name'],
          "Requires login?": { $in: [null, "N"] },
          "Status": { $nin: ["X"] },
          $or: [
            { 'Email-to-MMS': { $exists: true } },
            { 'Email-to-SMS': { $exists: true } },
          ],
        },
        "projection": {
          "Carrier": 1,
          "Gateway": { $ifNull: ["$Email-to-MMS", "$Email-to-SMS"] },
          "_id": 0,
          "roll": 1
        }
      });

      var carrier_config = {
        method: 'post',
        url: 'https://us-east-1.aws.data.mongodb-api.com/app/data-akark/endpoint/data/v1/action/find',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${accesstoken}`,
        },
        data: carrier
      };
      await axios(carrier_config)
      .then(function (response) {
      var temp =  [...new Set(response.data['documents'].map(obj => obj.Carrier))].sort();
      setGatewaydetails(response.data['documents'].map(item => ({
        [item.Carrier]: item.Gateway
      })));
      setCarrierOptions(temp.map((label, i) => ({ label, value: label })));
    })
    .catch(function (error) {
    });
    }
    setdialCode(show['dialCode'].length);
    setPhoneNumber(value);
  };


  const ShowList = ({shows}) => {
    return (
      <div className="show-list">
        {shows.map((show) => (
          <div key={show._id} className="show-list-item">
            <span>{show.show}</span>
            <button className="unsubscribe-button" onClick={() => handleUnsubscribe(show._id)}>
              <FontAwesomeIcon icon={faTimesCircle} className="unsubscribe-icon" />
              <span className="unsubscribe-popup">Unsubscribe</span>
            </button>
          </div>
        ))}
      </div>
    );
  };

  return (
    <div className="reminder-popup-container">
      <div className="reminder-popup-header">
        <span className="reminder-close-button" onClick={onClose}>x</span>
      </div>
      <div className="reminder-popup-content">
        {!loggedIn && !showOTP && (
          <div className="reminder-login-screen">
            <h2>Login to Manage Reminders</h2>
            <div className="login-form">
              <div className="form-group">
                <label htmlFor="phone-input">Phone Number</label>
                <div className="reminder-centered">
                  <PhoneNumberInput id="phone-input" value={phoneNumber} onChange={handlePhoneNumberChange} />
                </div>
              </div>
              <div className="form-group">
                <label htmlFor="carrier-dropdown">Select Carrier</label>
                <div className="reminder-centered">
                  <DropdownMenu id="carrier-dropdown" value={selectedCarrier} options={carrierOptions} onChange={handleCarrierChange} />
                </div>
              </div>
              <button className="login-button" onClick={handleLogin}>Login</button>
            </div>
          </div>
        )}
        {!loggedIn && showOTP && (
          <div className="reminder-otp-screen">
            <h4>A One-Time Password has been sent to {phoneNumber.slice(dialCode)}</h4>
            <div className="otp-form">
              <div className="form-group">
                <label htmlFor="otp-input">Enter OTP</label>
                <input
                    type="password"
                    className="otp-input"
                    maxLength="6" // Limiting input to 6 digits
                    value={otp}
                    onChange={(e) => handleOtpChange(e)}
                    inputMode="numeric"
                    onKeyDown={handleKeyDown}
                />
              </div>
              <button className="verify-otp-button" onClick={handleVerifyOTP}>Verify OTP</button>
            </div>
          </div>
        )}
        {loggedIn && (
          <div className="reminder-popup-main-content">
            <h2>Manage Reminders</h2>
            <ShowList shows={shows}/>
            <button className="unsubscribe-all-button" onClick={UnsubAll}>Unsubscribe All</button>
          </div>
        )}
      </div>
    </div>
  );
};

export default ReminderPopup;
